body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;

  > .ant-layout {
    height: 100%;
  }

  > div,
  section {
    height: 100%;

    > .ant-spin-container {
      height: 100%;
    }

    > .ant-layout {
      height: 100%;
    }
  }
}

.main-card {
  > .ant-card-head {
    padding: 0;
    border: none;

    .ant-card-head-title {
      font-size: 20px;
      color: #262e3f;
      line-height: 28px;
      padding: 24px;
      font-weight: bold;
    }
  }

  > .ant-card-body {
    padding: 0 24px 24px 24px;
  }

  .main-tab {
    .ant-tabs-tab-btn {
      height: 22px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &.full-height {
    height: 100%;

    .ant-card-body {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.primary-mark {
  color: #1ec692;
}

// antd button 默认样式修改
.ant-btn-default {
  color: #1cb887;
  border-color: #1ec692;
}

// antd dropdown menu 默认样式修改
.ant-dropdown-menu {
  padding: 8px 0;
  .ant-dropdown-menu-item {
    padding: 6px 24px;
    font-size: 14px;
    font-weight: 400;
    // color: #767f95;
    line-height: 22px;
  }
  .ant-dropdown-menu-item:hover {
    color: #1cb887;
  }
  .ant-dropdown-menu-item-active {
    background-color: #f9f9f9;
  }
  .ant-dropdown-menu-item-danger {
    color: #ff574c;
  }
  .ant-dropdown-menu-item-danger:hover {
    color: #ff574c;
    background-color: #f9f9f9;
  }
}

// antd modal 默认样式修改
.ant-modal {
  .ant-modal-content {
    .ant-modal-close-x {
      font-size: 14px;
    }
    .ant-modal-header {
      border: none;
      .ant-modal-title {
        font-size: 16px;
        color: #262e3f;
        line-height: 24px;
      }
    }
    .ant-modal-footer {
      border: none;
      padding: 16px 24px;
    }
  }
}


.ant-empty {
  .ant-empty-image {
    background: url('./assets/table-empty.png') no-repeat center center;
    background-size: 149px;
    min-height: 164.5px;
    width: 140px;
    margin: 0 auto;
    position: relative;

    svg {
      opacity: 0;
    }
  }
  .ant-empty-description {
    opacity: 0;
  }
}

.ant-layout-sider {
  background: transparent !important;
}

.menu-popover-overlay {
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 8px 0;

    .ant-menu-inline {
      padding: 0 !important;
      li {
        margin: 0;
        height: 34px;
        text-align: center;
        width: 90px;
        padding: 0 !important;

        &:hover {
          background-color: #f9f8f9;
        }
      }
    }
  }
}

.ant-btn-link {
  color: @primary-color !important;
}

iframe {
  display: none;
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);