.customFilter {
  padding: 20px 20px 1px 20px;

  :global {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .ant-input-group.ant-input-group-compact {
      display: flex;
    }

    .ant-input-disabled {
      background-color: white;
    }

    .ant-input-group-compact .ant-input-affix-wrapper {
      border-left: none;

      &:focus {
        box-shadow: none;
      }
    }

    .ant-form-item {
      margin-bottom: 8px;
    }

    .filter-btns {
      text-align: right;

      .ant-space-horizontal {
        padding-right: 2px;
      }
    }
  }
}

.listName {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;

  span.name {
    flex: 1;
    vertical-align: middle;
    max-width: calc(100% - 22px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :global {
    .anticon {
      vertical-align: middle;
      width: 22px;
      cursor: pointer;
    }
  }
}

.noPadding {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.operaBtns {
  :global {
    .anticon {
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.paginationWrap {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  :global {
    .left-pagination {

      .ant-pagination-item,
      .ant-pagination-prev,
      .ant-pagination-next {
        display: none;
      }

      li.ant-pagination-options {
        &::before {
          content: '每页 ';
        }

        &::after {
          content: ' 条';
        }
      }
    }
  }
}

.fullContent {
  height: calc(100vh - 350px);
}

.technicalSolutionWrap {
  td {
    min-height: 50px;
    height: 50px;
  }
}

.manage_detail-css {
  height: 100%;
}

.headerCss {
  width: 100%;
  padding: 14px 24px;
  border: 1px solid #EBEBEB;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
