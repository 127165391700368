.ant-layout-header {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.work-order-title {
  height: 28px;
  font-size: 20px;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  padding-left: 15px;
  border-left: 1px solid #D8D8D8;
}

.work-create-form {
  width: 800px;
  margin: 0 auto;
}

.back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detail-info {
  margin-top: 23px;
  border-bottom: 1px solid #CFD6E8;
  padding-bottom: 23px;

  .ant-row {
    margin-top: 12px;
  }
}

.chat-body {

  flex-grow: 1;

  .chat-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    .chat-icon {
      width: 49px;
      height: 49px;
      flex-shrink: 0;
      background: #D8D8D8;
      border-radius: 25px;
      margin-right: 1rem;
      line-height: 49px;
      text-align: center;
      font-size: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .chat-content {
      display: flex;
      flex-direction: column;
      width: 80%;
    }

    .chat-time {
      font-size: 14px;
      font-weight: 400;
      color: #767F95;
      line-height: 20px;
    }

    .chat-text {
      font-size: 18px;
      font-weight: 400;
      color: #262E3F;
      line-height: 25px;

      word-break: break-all;
    }

    .chat-attachment {
      margin-top: .5rem;
      max-width: 100%;
    }
  }

  &.body-revert .chat-row {
    flex-direction: row-reverse;
    text-align: right;

    .chat-icon {
      margin-right: 0rem;
      margin-left: 1rem;
    }

    .chat-content {
      align-items: flex-end;
    }

    .chat-text {
      text-align: left;
    }
  }
}

.card-content {
  .ant-card-body {
    display: flex;
    flex-direction: column;
  }
}

.border-top {
  border-top: 1px dashed #D8D8D8;
}

.ant-upload-list {
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-upload-list-text-container {
    margin-right: 2rem;
    margin-bottom: 1rem;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    padding: .3rem 1rem;

    .ant-upload-list-item {
      margin-top: 0;
    }
  }
}

.back-button {
  padding-left: 0;
}

.ant-breadcrumb {
  ol {
    align-items: center;
  }

  .custom-button {
    padding: 0;
    height: auto;
  }

}


.status-with-badge {
  display: flex;
  flex-direction: row;
  align-items: center;

  .badge {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-right: .5rem;
  }

  .success-badge {
    background-color: #1EC692;
  }

  .error-badge {
    background-color: #FF574C;
  }
}

.ant-upload-list {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;

  overflow-x: auto;

  .ant-upload-list-item-name {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.attachment-list {
  display: flex !important;
  padding-bottom: 1rem;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  .attachment-item {
    margin-right: 1rem;

    span {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.list-revert {
    .attachment-item {
      margin-right: 0;
      margin-left: 1rem;
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);